<script lang="ts" setup>
import slugify from "slugify";
import type { ProductImage } from "~/models/products";

defineOptions({
  inheritAttrs: false,
});

const props = defineProps<{
  image?: ProductImage | null;
  color?: string | null;
  isAvailable: boolean;
  isNew: boolean;
  isOutOfStock: boolean;
  isPromo: boolean;
  sizes: string;
}>();

const { locale } = useI18n();

const bgWineColor = computed(() => slugify(props.color ?? "", { lower: true }));
</script>

<template>
  <div class="product-image-wrapper">
    <div v-if="isNew" class="absolute left-0 top-0 z-10 size-24">
      <img :src="`/icons/new-badge_${locale}.png`" alt="">
    </div>

    <div v-if="color" class="absolute right-3 top-3 z-10 h-10 w-2 rounded" :class="`bg-wine-${bgWineColor}`" />

    <div v-if="isPromo" class="absolute bottom-3 left-3 z-10">
      <UBadge variant="solid" color="primary" size="lg" class="rounded">
        {{ $t("product.status.promotion", "Promo") }}
      </UBadge>
    </div>

    <div v-if="isOutOfStock" class="absolute bottom-3 right-3 z-10">
      <UBadge variant="solid" color="gray" size="lg" class="rounded">
        {{ $t("product.status.out-of-stock", "Tijdelijk niet in stock") }}
      </UBadge>
    </div>

    <img
      :src="image?.url ?? '/logo/placeholder.svg'"
      :alt="image?.alt"
      :class="$attrs.class"
      :sizes="sizes"
      loading="lazy"
      onerror="this.onerror=null; this.src='/logo/placeholder.svg';"
    >
  </div>
</template>

<style scoped>
.product-image-wrapper {
  @apply relative h-[300px];
}
</style>
